.comments-container {
	display: flex;
}

.comments {
	padding-inline: 1rem;
	min-width: 60%;
	height: 100vh;
	overflow-y: scroll;
	margin-left: 40%;
}

.comment-textarea {
	background-color: var(--lightest);
	border: 2px solid var(--dark);
	color: var(--text-color);
	font-family: "Bauhaus", sans-serif;
	padding: 1rem;
	resize: none;
	font-size: 1.125em;
	display: block;
	width: 100%;
	border-radius: 15px;
	transition: border-color 0.3s ease;
}

.comment-textarea:focus {
	border-color: var(--text-color);
	background-color: white;
}

.comment-textarea-label {
	font-size: 1.5rem;
}

.comment-card-container {
	margin-left: 1rem;
	padding-left: 2rem;
	border: solid var(--text-secondary);
	border-width: 0 0 0 3px;
}

@media only screen and (max-width: 992px) {
	.comments-container {
		flex-direction: column;
	}

	.comments {
		margin-left: 0px;
		min-height: 0px;
	}
}
