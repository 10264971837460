.user-answer-container {
	display: flex;
}

.user-answer {
	min-width: 60%;
	height: 100vh;
	overflow-y: scroll;
	margin-left: 40%;
}

.custom-textarea {
	background-color: var(--lightest);
	border: 2px solid var(--dark);
	color: var(--text-color);
	font-family: "Bauhaus", sans-serif;
	padding: 1rem;
	resize: none;
    font-size: 1.25em;
	display: block;
	width: 100%;
	border-radius: 15px;
	transition: border-color 0.3s ease;
}

.custom-textarea:focus {
	border-color: var(--text-color);
	background-color: white;
}

.textarea-label {
    font-size: 1.5em;
}

.submit-button {
    font-size: 1.25em;
}

.modal-content {
	border: none;
}

.modal-header {
	border: none;
}

.modal-title {
	border: none;
	font-size: 1.5rem;
}

.modal-body {
	border: none;
	font-size: 1.25rem;
	padding-inline: 2rem;
}

.modal-footer {
	border: none;
	padding-top: 0px;
}

@media only screen and (max-width: 992px) {
	.user-answer-container {
		flex-direction: column;
	}

	.user-answer {
		margin-left: 0px;
		min-height: 0px;
	}
}
