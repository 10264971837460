.view-height {
	height: 100vh;
}

.logout-bar {
	top: 0;
	left: 0;
	width: calc(100% + var(--bs-gutter-x));
	margin-left: calc(var(--bs-gutter-x) * -0.5);
	margin-right: calc(var(--bs-gutter-x) * -0.5);
}

.reveal-text {
	font-size: calc(3rem + 2vw);
	margin-top: calc(10rem + 2vw);
	margin-inline: 2rem;
}

.prompt-reveal-text {
	font-size: calc(2rem + 2vw);
	max-width: 80%;
}

.prompt-text {
	font-size: 1.5rem;
}

.shapedividers_com-3810 {
	overflow: hidden;
	position: relative;
}
.shapedividers_com-3810::before {
	content: "";
	font-family: "shape divider from ShapeDividers.com";
	position: absolute;
	z-index: 3;
	pointer-events: none;
	background-repeat: no-repeat;
	bottom: -0.1vw;
	left: -0.1vw;
	right: -0.1vw;
	top: -0.1vw;
	background-size: 100% 200px;
	background-position: 50% 100%;
	background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M35.28 1.67c-3.07-.55-9.27.41-16.15 0-6.87-.4-13.74-.58-19.13.1v.4h35.28z" fill="%23fef5ec"/><path d="M35.28 1.16c-3.17-.8-7.3.4-10.04.56-2.76.17-9.25-1.47-12.68-1.3-3.42.16-4.64.84-7.04.86C3.12 1.31 0 .4 0 .4v1.77h35.28z" opacity=".5" fill="%23fef5ec"/><path d="M35.28.31c-2.57.84-7.68.3-11.8.43-4.1.12-6.85.61-9.57.28C11.18.69 8.3-.16 5.3.02 2.3.22.57.85 0 .87v1.2h35.28z" opacity=".5" fill="%23fef5ec"/></svg>');
}

@media (min-width: 2100px) {
	.shapedividers_com-3810::before {
		background-size: 100% calc(2vw + 200px);
	}
}

.bounce-animation {
	cursor: pointer;
	animation: doubleBounce 4s infinite ease-in-out;
}

@keyframes doubleBounce {
	25% {
		transform: translateY(0px);
	}
	12.5%,
	37.5% {
		transform: translateY(5px);
	}
	0%,
	50% {
		transform: translateY(0px);
	}
}
