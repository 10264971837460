.responses-container {
	display: flex;
}

.responses {
	padding-inline: 1rem;
	min-width: 60%;
	height: 100vh;
	overflow-y: scroll;
	margin-left: 40%;
}

.circle-icon {
	font-size: 0.5em;
	margin: 0 0.8em;
	color: #5c7c8d;
}

.sort-buttons {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.nav-buttons {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-left: auto;
}

.sort-btn {
	border: none;
	background-color: transparent;
	border: solid transparent;
	border-width: 0 0 3px 0;
}

.sort-btn.active {
	border: solid var(--text-color);
	border-width: 0 0 3px 0;
}

.sort-btn:hover {
	border: solid var(--dark);
	border-width: 0 0 3px 0;
}

.fontawesome-btn {
	color: var(--text-color);
	font-size: 1.25rem;
}

.fontawesome-btn:hover {
	color: var(--dark);
	font-size: 1.25rem;
}

.logout-btn {
	border: none;
	background-color: transparent;
}

.like-btn,
.comment-btn {
	border: none;
	border-radius: 0.5rem;
	text-decoration: none;
	height: 100%;
	width: 70%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: var(--text-color);
	background-color: transparent;
	padding: 0 10% 0 10%;
	margin: 0;
}

.like-icon,
.comment-icon {
	color: var(--text-color);
	padding-top: 0.5rem;
	margin: 0.1rem;
}

.like-btn:hover,
.comment-btn:hover {
	background-color: rgba(0, 0, 0, 0.05);
}

.number {
	font-size: 0.95rem;
	padding: 0rem 1rem 0.5rem 1rem;
}

.card {
	border: none;
	box-shadow: 3px 3px 4px 2px rgba(0, 0, 0, 0.05);
}

.card.mine {
	border: 2px solid var(--dark);
	margin-top: 2rem !important;
	margin-bottom: 2rem !important;
}

@media only screen and (max-width: 992px) {
	.responses-container {
		flex-direction: column;
	}

	.responses {
		margin-left: 0px;
		min-height: 0px;
	}
}
