:root {
	--lightest: #fef5ec;
	--medium: #fbe0c3;
	--dark: #ffbb98;
	--text-color: #273841;
	--text-secondary: #5c7c8d;

	background-color: var(--medium);
}

@font-face {
	font-family: "Bauhaus";
	src: url("./fonts/BauhausITCbyBT-Medium.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

.lightest {
	background-color: var(--lightest);
}

.medium {
	background-color: var(--medium);
}

.dark {
	background-color: var(--dark);
}

.text-primary {
	color: var(--text-color) !important;
	font-family: "Bauhaus", sans-serif;
}

.text-body {
	color: var(--text-color) !important;
}

.text-secondary {
	color: var(--text-secondary) !important;
	font-family: "Bauhaus", sans-serif;
}

.pt-10 {
	padding-top: 10vw !important;
}

.custom-btn,
.custom-btn-secondary {
	border: 2px solid var(--dark);
	border-radius: 30px;
	color: var(--text-color);
	cursor: pointer;
	display: inline-block;
	font-weight: 600;
	font-size: 1.125rem;
	padding: 0 1.5rem;
	line-height: 3rem;
	text-align: center;
	text-decoration: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	font-family: "Bauhaus", sans-serif;
}

.custom-btn:hover,
.custom-btn-secondary:hover {
	background-color: var(--dark);
}

.custom-btn:active,
.custom-btn-secondary:active {
	transform: translate(2px, 2px);
}

.custom-btn {
	background-color: var(--medium);
}

.custom-btn-secondary {
	background-color: var(--lightest);
}

.custom-btn.small, .custom-btn-secondary.small {
	padding: 0 1rem;
	line-height: 2rem;
}