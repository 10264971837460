.shapedividers_com-1228 {
	overflow: hidden;
	position: relative;
}
.shapedividers_com-1228::before {
	content: "";
	font-family: "shape divider from ShapeDividers.com";
	position: absolute;
	bottom: -1px;
	left: -1px;
	right: -1px;
	top: -1px;
	z-index: 3;
	pointer-events: none;
	background-repeat: no-repeat;
	background-size: 100% 90px;
	background-position: 50% 0%;
	background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23fbd8c2"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23fbd8c2"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23fbd8c2"/></svg>');
}

.landing-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: min(5rem, 12vw);
}

.landing-logo img {
	max-width: 50%;
	max-height: 50%;
	width: auto;
}

.landing-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-inline: 10vw;
}

.landing-description {
	font-size: calc(1rem + 0.25vw);
	line-height: calc(1.75rem + 0.75vw);
	color: var(--text-secondary);
	margin-bottom: 1.5rem;
	max-width: 50ch;
}

.top-row {
	justify-content: flex-start;
}

.middle-row {
	width: 100%;
	display: flex;
	align-items: flex-start;
	padding-top: 2em;
}

.bottom-row {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-top: 1.5rem;
}

.bottom-row div {
	display: flex;
	gap: 1rem;
}

.disclaimer {
	font-size: calc(0.75rem + 0.25vw);
	color: var(--text-secondary);
	text-decoration: none;
	border: none;
	outline: none;
}

.shapedividers_com-5341 {
	overflow: hidden;
	position: relative;
}
.shapedividers_com-5341::before {
	content: "";
	font-family: "shape divider from ShapeDividers.com";
	position: absolute;
	z-index: 3;
	pointer-events: none;
	background-repeat: no-repeat;
	bottom: -0.1vw;
	left: -0.1vw;
	right: -0.1vw;
	top: -0.1vw;
	background-size: 100% 90%;
	background-position: 50% 0%;
	background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23ffbb98"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23ffbb98"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23ffbb98"/></svg>');
}

@media (min-width: 768px) {
	.shapedividers_com-1228::before {
		background-size: 100% 90px;
		background-position: 50% 0%;
	}
}

@media (min-width: 1200px) {
	.shapedividers_com-1228::before {
		bottom: -0.1vw;
		left: -0.1vw;
		right: -0.1vw;
		top: -0.1vw;
		background-size: 400px 100%;
		background-position: 100% 50%;
		background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2.17 35.28" preserveAspectRatio="none"><path d="M1.67 0c-.55 3.07.41 9.27 0 16.14-.4 6.88-.58 13.75.1 19.14h.4V0z" fill="%23ffbb98"/><path d="M1.16 0c-.8 3.17.4 7.29.56 10.04C1.89 12.8.25 19.3.42 22.71c.16 3.43.84 4.65.86 7.05.03 2.4-.88 5.52-.88 5.52h1.77V0z" opacity=".5" fill="%23ffbb98"/><path d="M.31 0c.84 2.56.3 7.68.43 11.79.12 4.1.61 6.86.28 9.58-.33 2.73-1.18 5.61-1 8.61.19 3 .82 4.73.84 5.3h1.2V0z" opacity=".5" fill="%23ffbb98"/></svg>');
	}

	.shapedividers_com-5341 {
		display: none;
	}

	.landing-container {
		padding-left: 12rem;
	}
}